/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import LayoutMain from '@layouts/layout-main/layout-main';

const LayoutBase = (props) => {
	if (props.uri === '/blog') {
		props.data.page['title'] = 'Blog';
	}

	if (!props?.data?.page) {
		return props.children;
	}

	return (
		<LayoutMain {...props} />
	);
};

export default LayoutBase;

/**
 * External dependencies.
 */
import { useMemo } from 'react';

export default function usePageHead(page, context) {
	const pageMetaTitle = page?.metaTitle?.title;
	const metaTitle = context.metaTitlePrefix ? `${context.metaTitlePrefix} - ${pageMetaTitle || page.title}` : pageMetaTitle || page.title;

	const getSeoTitle = useMemo(() => page.uri === '/' ? 'Home' : metaTitle, [page.uri, metaTitle]);
	const isHomePage = useMemo(() => page.uri === '/', [page.uri]);

	return {
		getSeoTitle,
		isHomePage,
	};
}

/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'gatsby';

const NavigationLink = ({ children, ...props }) => (
	<Link
		activeClassName="active"
		{...props}
	>
		{children}
	</Link>
);

export default NavigationLink;

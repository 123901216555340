/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

/**
 * Internal dependencies.
 */
import Navigation from '@blocks/navigation/navigation';
import Logo from '@components/logo/logo';
import Shell from '@components/shell/shell';

const Header = ({ menu, showLargeLogo }) => (
	<header className="header">
		<Shell>
			<div className="header__inner">
				<div className="header__logo">
					<Logo showLargeLogo={showLargeLogo} />
				</div>

				<Navigation
					menu={menu}
					className="header__nav"
				/>
			</div>
		</Shell>
	</header>
);

Header.propTypes = {
	menu: PropTypes.object,
	showLargeLogo: PropTypes.bool,
};

Header.defaultProps = {
	showLargeLogo: false,
};

export const query = graphql`
    fragment PageMenuFragment on WpMenu {
        slug
        menuItems {
            nodes {
                id
                url
                title
                path
            }
        }
    }
`;

export default Header;

export const slideFadeInAnimation = (offset = 100) => ({
	initial: 'hidden',
	animate: 'visible',
	variants: {
		hidden: {
			opacity: 0,
			transform: `translateY(100px)`,
		},
		visible: {
			opacity: 1,
			transform: 'translateY(0px)',
		},
	},
	transition: {
		ease: 'easeInOut',
		delay: offset / 1000,
		duration: .5,
	},
});

export const fadeInAnimation = {
	initial: 'hidden',
	animate: 'visible',
	variants: {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
		},
	},
	transition: {
		ease: 'easeInOut',
		duration: .5,
	},
};

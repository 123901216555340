/**
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import NavigationLink from '@blocks/navigation/navigation-link';

const Navigation = ({ menu, className }) => {
	if (!menu) {
		return null;
	}

	return (
		<nav className={cx('nav', className)}>
			<ul>
				{menu.menuItems.nodes.map((menuItem) => (
					<li key={menuItem.id}>
						<NavigationLink to={menuItem.path}>{menuItem.title}</NavigationLink>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Navigation;

/**
 * External dependencies.
 */
import React from 'react';
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group';

const timeout = 500;
const getTransitionStyles = {
	entering: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		opacity: 1,
	},
	exiting: {
		transition: `all ${timeout}ms ease-in-out`,
		opacity: 0,
		transform: 'translateY(100px)',
	},
};

class PageTransition extends React.PureComponent {
	render() {
		const { children, location } = this.props;

		return (
			<TransitionGroup>
				<ReactTransition
					key={location.pathname}
					appear
					timeout={{
						enter: timeout,
						exit: timeout,
					}}
				>
					{status => (
						<div
							style={{
								...getTransitionStyles[status],
							}}
						>
							{children}
						</div>
					)}
				</ReactTransition>
			</TransitionGroup>
		);
	}
}

export default PageTransition;

/**
 * External dependencies.
 */
import React from 'react';
import { motion } from 'framer-motion';

/**
 * Internal dependencies.
 */
import usePageHead from '@hooks/use-page-head';
import { fadeInAnimation } from '@utils/framer-animations';
import PageTransition from '@blocks/page-transition/page-transition';
import SEO from '@blocks/seo/seo';
import Header from '@components/header/header';
import Shell from '@components/shell/shell';

const LayoutMain = ({
	data: { page, menu },
	pageContext,
	location,
	children,
}) => {
	const { isHomePage, getSeoTitle } = usePageHead(page, pageContext);

	return (
		<div className="wrapper">
			<motion.span
				key={page?.metaTitle?.title || page.title}
				className="wrapper__title"
				{...fadeInAnimation}
			>
				{page?.metaTitle?.title || page?.title}
			</motion.span>

			<SEO title={getSeoTitle} />

			<Header
				menu={menu}
				showLargeLogo={isHomePage}
			/>

			<main className="main">
				<PageTransition location={location}>
					<Shell>
						{children}
					</Shell>
				</PageTransition>
			</main>
		</div>
	);
};

LayoutMain.defaultProps = {
	data: {
		page: {
			title: '',
			metaTitle: {
				title: '',
			},
		},
	},
	pageContext: {
		metaTitlePrefix: '',
	},
};

export default LayoutMain;

/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';

/**
 * Internal dependencies.
 */

const Logo = ({ className, showLargeLogo, ...props }) => (
	<Link
		className={cx('logo', { 'is-large': showLargeLogo }, className)}
		to="/"
		{...props}
	>
		<svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M39.429 16.97H19.714v16.97H39.43c5.442 0 9.857-3.801 9.857-8.485 0-4.685-4.415-8.485-9.857-8.485zM39.429 36.06H19.714v16.97H39.43c5.442 0 9.857-3.8 9.857-8.485 0-4.684-4.415-8.484-9.857-8.484z"	fill="currentColor"/>
			<path stroke="red" strokeOpacity=".56" strokeWidth="3" d="M19 61.5h8" className={cx('rgb', {'hide': !showLargeLogo})} />
			<path stroke="#0F0" strokeOpacity=".57" strokeWidth="3" d="M29 61.5h9" className={cx('rgb', {'hide': !showLargeLogo})} />
			<path stroke="#10A9FF" strokeWidth="3" d="M40 61.5h8" className={cx('rgb', {'hide': !showLargeLogo})} />
		</svg>

	</Link>
);

Logo.propTypes = {
	className: PropTypes.string,
	showLargeLogo: PropTypes.bool,
};

Logo.defaultProps = {
	showLargeLogo: false,
};

export default Logo;

/**
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

const Shell = ({ className, children }) => (
	<div className={cx('shell', className)}>{children}</div>
);

export default Shell;
